.relative {
  position: relative;
}

.m-0 {
  margin: 0px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mt-5 {
  margin-top: 1.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.h-\[220px\] {
  height: 220px;
}

.w-full {
  width: 100%;
}

.w-\[60px\] {
  width: 60px;
}

.flex-1 {
  flex: 1 1 0%;
}

.cursor-pointer {
  cursor: pointer;
}

.list-none {
  list-style-type: none;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-5 {
  gap: 1.25rem;
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.p-3 {
  padding: 0.75rem;
}

.p-0 {
  padding: 0px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.leading-loose {
  line-height: 2;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.text-zinc-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity));
}

.outline {
  outline-style: solid;
}

.outline-zinc-100 {
  outline-color: #f4f4f5;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.q-layout,
.custom_modal .body {
  background-color: #eeeff8;
  color: #3F4254;
  font-size: 14px;
}

.q-table__card {
  color: #3F4254;
}

.text-meta {
  color: var(--q-meta);
}

.bg-meta {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

h1,  h2,  h3,  h4,  h5,  h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* 针对所有的 input number 输入框 */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.main_shadow {
  --tw-shadow-color: #eef2ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.sliver_header .q-toolbar {
  justify-content: space-between;
}

.sliver_header .q-toolbar > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.sliver_header .q-toolbar {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.sliver_header .q-toolbar .menu_box {
  display: flex;
  align-items: center;
}

.sliver_header .q-toolbar .menu_box > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.admin_menu_item .icon_box {
  min-width: auto;
}

.sliver_header .q-toolbar .menu_box .q-tabs__content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.sliver_header .q-toolbar, 
.sliver_header .sub_menu {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.sliver_header .sub_menu {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.child_menu_active {
  background-color: var(--q-accent);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.login_page {
  background-image: url("../../../public/bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login_page::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  opacity: 0.25;
}

.q-card.custom_card.all {
  border-radius: 0.5rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #eef2ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.login_page .q-card {
  z-index: 20;
}

@media (min-width: 1200px) {
  .login_page .q-card {
    width: 480px;
  }
}

.login_page .q-card .q-form > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.q-field--outlined .q-field__control:before {
  --tw-border-opacity: 1;
  border-color: rgb(239 246 255 / var(--tw-border-opacity));
}

.q-field__label {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.q-field--float:not(.q-field--focused) .q-field__label {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

.add_supplier .box .box_body, 
.customer_form .box .box_body {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.25rem;
}

.add_supplier .form_box > :not([hidden]) ~ :not([hidden]), 
.customer_form .form_box > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.sliver_page > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.swal2-confirm.q-btn.customCF {
  margin-right: 1rem !important;
}

.swal2-confirm.q-btn, 
.swal2-cancel.q-btn {
  display: flex !important;
}

@media (min-width: 1200px) {
  .swal2-popup {
    width: 350px !important;
  }
}

.swal2-html-container {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  line-height: 2 !important;
}

.swal2-title {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-weight: 700 !important;
}

.swal2-container {
  z-index: 9999999 !important;
}

.swal2-icon.swal2-error {
  color: var(--q-negative) !important;
  border-color: var(--q-negative) !important;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  background-color: var(--q-negative) !important;
}

.q-table thead th {
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
  background-color: #ebecf0;
}

.q-table tbody td {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.q-table tbody tr:nth-child(even) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.q-table thead, .q-table tr, .q-table th, .q-table td,
.q-table__bottom,
.q-card--bordered {
  border-color: #EBEDF3;
}

/*q-btn*/

.q-btn.q-btn--dense {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.q-dialog .q-bar {
  background-color: var(--q-primary);
  height: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.q-dialog .q-bar h5 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.custom_upload_card .q-bar,
.custom_bar {
  background-color: var(--q-secondary);
  height: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-to: rgb(29 78 216 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: rgb(37 99 235 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to);
  --tw-gradient-to: #3b82f6;
}

.custom_upload_card .q-bar h5 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.custom_upload_card .upload_box > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.sliver_btn_lg {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.custom_card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom_card_header h6 {
  padding-left: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  border-left: 4px solid var(--q-primary);
}

.order_list_table h5 {
  position: relative;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.order_list_table .q-table__top {
}

.custom_timeline_entry .q-timeline__subtitle {
  padding-top: 0.5rem;
}

.custom_timeline_entry.modal .q-timeline__title {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.custom_box {
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.75rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #dbeafe;
  --tw-shadow: var(--tw-shadow-colored);
}

.modal_lg .q-dialog__inner--minimized > div {
  width: 100% !important;
}
.el-popper{
  z-index: 9999 !important;
}
.el-upload-list--picture-card{
  margin-right: 3px !important;
}

@media (min-width: 1200px) {
  .modal_lg .q-dialog__inner--minimized > div {
    max-width: 820px !important;
  }
}

.filter_ul {
  margin: 0px;
  display: flex;
  list-style-type: none;
  align-items: center;
}

.filter_ul > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.filter_ul {
  padding: 0.75rem;
}

@media (min-width: 1200px) {
  .lg\:w-\[480px\] {
    width: 480px;
  }

  .lg\:w-\[110px\] {
    width: 110px;
  }

  .lg\:w-\[240px\] {
    width: 240px;
  }
}
